/* Loading.css */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.react-logo {
    width: 100px; /* Adjust the size as needed */
    height: 100px;
}
