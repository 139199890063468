.image{
    width: 400px;
    height: auto;
}
.bodyText {
    max-width: 400px;
}
.h1{
    justify-content: center;
    align-items: center;
}
.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.team-member {
    width: 300px;
    margin: 20px;
    text-align: center;
}