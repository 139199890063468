.statsContainer {
    display: flex;
    align-items: center;
}

.pieChartContainer {
    flex: 1;
    max-width: 50%;
    padding-left: 15px;
}

.textContainer {
    flex: 1;
    max-width: 50%;
    display: flex;
    text-align: center;
}

.text {
    margin-left: 20px; /* Adjust as needed */
}
.assessmentAverageHeader{
    color: blue;
    font-weight: bold;
}
